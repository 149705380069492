import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { GatsbyBrowser } from 'gatsby';
import './src/styles/global.css';

const queryClient = new QueryClient();

const GlobalWrapper: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	useEffect(() => {
		const handleRejection = (event: PromiseRejectionEvent) => {
			event.preventDefault();
			console.warn('Unhandled promise rejection:', event.reason);
		};

		window.addEventListener('unhandledrejection', handleRejection);

		return () => {
			window.removeEventListener('unhandledrejection', handleRejection);
		};
	}, []);

	return (
		<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
	);
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
	element,
}) => <GlobalWrapper>{element}</GlobalWrapper>;
